import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      details: this.setDetailInitialValues(),
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
    }
  },
  methods: {
    ...mapActions([
      'getCompanyNonConformities',
      'getCompanyAuditRecordById',
      'getCompanyRootCausesList'
    ]),
    async getUpdatedNonConformity() {
      await this.getCompanyAuditRecordById(this.$route.params.id);
      await this.getCompanyNonConformities({
        id : this.selectedCompanyAuditRecord[this.selectedType].id,
        type : this.selectedType
      });
    },
    setDetailInitialValues() {
      return {
        id : null,
        ncr_type : 'ncr',
        description : null,
        training_need : 0,
        change_of_procedures : 0,
        recurrence_ashore : '',
        recurrence_on_site : '',
        root_cause_ids : []
      }
    },
    setOneOrZero(value) {
      return value === 0 ? 1 : 0;
    },
    groupByParentCause(rootCauses) {
      const rootCausesList = this.company_root_causes_list[this.selectedType];
      let groupByParentId = _.groupBy(rootCauses, 'parent_id');
      groupByParentId = _.map(groupByParentId, (item, key) => {
        const parentCause = rootCausesList.filter(parent => {
          return parent.id === parseInt(key);
        })

        return {
          name : parentCause[0].name,
          selected_causes : item
        }
      });

      return groupByParentId;
    },
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  computed : {
    ...mapGetters([
      'companyNonConformities',
      'selectedCompanyAuditRecord',
      'company_root_causes_list'
    ]),
    selectedType() {
      return this.$route.params.type;
    }
  }
}
