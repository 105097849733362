<template>
  <div>
    <div class="card border-0">
      <form @submit.prevent="" class="w-100">
        <div class="row">
          <div class="col-12 text-right">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-2" type="submit" @click="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
            </template>
            <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editDetails">Edit Details</button>

          </div>
        </div>

        <template v-if="toEdit">
          <div class="mb-3">
            <table class="w-100">

              <tr>
                <td style="width: 25%">Category</td>
                <td>
                  <select class="form-control form-control-sm my-2" v-model="oe.category_id">
                    <option :value="null">-- Select Category --</option>
                    <template v-for="category in companyOECategories">
                      <option :value="category.id">{{ category.name }}</option>
                    </template>
                  </select>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Ref to SMS/Regulator</td>
                <td>
                  <input type="text" class="form-control form-control-sm w-100" v-model="oe.reference">
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Objective Evidence Description</td>
                <td>
                  <vue-editor v-model="oe.description" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Corrective Action Request</td>
                <td>
                  <vue-editor v-model="oe.ca_request" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectified on the spot</td>
                <td>
                  <div class="form-group row no-gutters mb-1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="oe.rectified === 1" @click="oe.rectified = setOneOrZero(oe.rectified)">
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td style="width: 25%">Rectified Date</td>
                <td>
                  <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="oe.rectified_date">
                </td>
              </tr>

              <tr>
                <td style="width: 25%">Rectification Comment</td>
                <td>
                  <vue-editor v-model="oe.rectified_comment" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>


            </table>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            <table class="w-100">
              <tr>
                <td style="width: 25%">Category</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].category.name  }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Ref to SMS/Regulator</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].reference }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Objective Evidence Description</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].description | stripHtmlTags }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Corrective Action Request</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].ca_request | stripHtmlTags }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectified on the spot</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].rectified === 1 ? 'YES' : 'NO' }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectified Date</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].rectified_date.human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectification Comment</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']].rectified_comment | stripHtmlTags }}
                </td>
              </tr>

            </table>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import CompanyRootCauseSelector from "@/components/modules/ia/company/nonConformity/details/CompanyRootCauseSelector.vue";
import {DataService} from "@/services/DataService";
import CompanyObjectiveEvidenceMixin from "@/mixins/CompanyObjectiveEvidenceMixin";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";
import dateMixin from "@/mixins/DateMixin";
export default {
  name : 'CompanyOEMainDetails',
  components: {CompanyRootCauseSelector},
  mixins : [CompanyObjectiveEvidenceMixin, CompanyNonConformityMixin, dateMixin],
  data() {
    return {
      toEdit : false,
    }
  },
  methods: {
    ...mapActions([
      'updateCompanyObjectiveEvidence',
    ]),
    async editDetails() {
      await this.setDetails();
      this.toEdit = true;
    },
    async submit() {
      const updateResponse = this.updateCompanyObjectiveEvidence(this.oe);
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF NCR/OBS DETAIL');
      }
      await AlertService.successAlert('Updated successfully', 'UPDATING OF NCR/OBS DETAIL');
      await this.getUpdatedNonConformity();
      this.oe = this.setOeDetailInitialValues();
      this.toEdit = false;
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.oe = this.setOeDetailInitialValues();
        this.toEdit = false;
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.oe = this.setOeDetailInitialValues();
        this.toEdit = false;
      }
    },
    async setDetails() {
      const currentDetails = this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].objective_evidences[this.$attrs['active-oe-index']]

      this.oe = {
        id : currentDetails.id,
        category_id : currentDetails.category_id,
        description : currentDetails.description,
        ca_request : currentDetails.ca_request,
        reference : currentDetails.reference,
        rectified : currentDetails.rectified,
        rectified_date : currentDetails.rectified_date['date-picker'],
        rectified_comment : currentDetails.rectified_comment,
      }
    },
  },
  created() {

  },
  computed : {
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      const details = this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].objective_evidences[this.$attrs['active-oe-index']]
      const originalData = {
        category_id : details.category_id ?? null,
        description : details.description,
        ca_request : details.ca_request,
        reference : details.reference,
        rectified : details.rectified,
        rectified_date : details.rectified_date['date-picker'],
        rectified_comment : details.rectified_comment,
      }

      const editedData = {
        category_id : this.oe.category_id,
        description : this.oe.description,
        ca_request : this.oe.ca_request,
        reference : this.oe.reference,
        rectified : this.oe.rectified,
        rectified_date : this.oe.rectified_date,
        rectified_comment : this.oe.rectified_comment
      }

      return DataService.checkIfChanged(originalData, editedData);

    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
