export default {
 computed:{
   currentDate(){
     return new Date().toISOString().split("T")[0]
   },
   minimumDate(){
     return new Date('1900-01-01').toISOString().split("T")[0]
   }

 }
}
