<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="font-weight-bold btn border-secondary w-100 text-left"  data-toggle="modal" :data-target="'#'+id" style="min-height: 30px;">
          <template v-for="cause in groupByParentCause(updatedItemsValue)">
            <span class="font-weight-bold">{{ cause.name }}</span>
            <div class="pl-3" v-for="childCauses in cause.selected_causes">
              <font-awesome-icon
                style="font-size: 5px; margin-bottom: 2px"
                icon="circle"/>
              {{ childCauses.name }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div style="overflow-y: auto" class="modal fade" data-backdrop="static" data-keyboard="false" :id="id" tabindex="-2" :aria-labelledby="id + 'Label'" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <form class="modal-content">
          <div class="modal-header">
            <div class="modal-title w-100 text-center">
              <h5 class="font-weight-bold">SELECT ROOT CAUSES</h5>
            </div>
          </div>
          <div class="modal-body">
            <nav class="nav nav-pills nav-justified mb-2">
              <a :class="['gray nav-item nav-link', 'align-middle', 'cursor-pointer', {'active_custom': activeIndex === index}]" v-for="(cause_category, index) in company_root_causes_list[$route.params.type]" @click="activeIndex = index">
                {{ cause_category.name }}
              </a>
            </nav>

            <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

            <div class="nav nav-pills nav-justified mb-2">
              <div class="nav-item text-left" v-for="chunkChildrenCauses in chunkCausesChildren">
                <div v-for="data in chunkChildrenCauses">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input checkbox-input" type="checkbox" :id = "company_root_causes_list[$route.params.type][activeIndex].name + '_' + data.name" @click="setItem(data.id)" :checked="updatedItems.includes(data.id)">
                    <label :for="company_root_causes_list[$route.params.type][activeIndex].name + '_' + data.name" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">{{ data.name }}</label>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

          <h6 class="ml-3" v-if="groupByParentCause(updatedItemsValue).length > 0">Selected Causes</h6>
          <table>
            <tr v-for="cause in groupByParentCause(updatedItemsValue)">
              <td style="width: 25%" class="align-top">
                <span class="ml-4 font-weight-bold"> {{ cause.name }} </span>
              </td>
              <td class="pr-3">
                <div class="font-weight-bold btn border-secondary w-100 text-left pb-0 m-1">
                  <template class="pl-3" v-for="childCauses in cause.selected_causes">
                    <span class="word-wrap mr-2">
                      <span @click="setItem(childCauses.id)">{{ childCauses.name }}</span>
                      <span class="cursor-pointer close-selected-cause" aria-hidden="true" @click="setItem(childCauses.id)">&times;</span>
                    </span>
                  </template>
                </div>
              </td>
            </tr>
          </table>

          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" @click="cancelSelected">Cancel</button>
            <button type="button" class="btn btn-sm btn-primary" @click="doneSelected">Done</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import _ from 'lodash';
import {DataService} from "@/services/DataService";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";

export default {
  name : 'CompanyRootCauseSelector',
  mixins : [CompanyNonConformityMixin],
  props : {
    id:{
      type:String,
      default: 'root-cause'
    },
    items : {
      type : Array,
      default : []
    },
  },
  data() {
    return {
      activeIndex : 0,
      updatedItems : []
    }
  },
  methods : {
    ...mapActions([
      'getCompanyRootCausesList'
    ]),
    cancelSelected() {
      $('#' + this.id).modal('hide');
    },
    setItem(causeId) {
      this.updatedItems = DataService.pushOrRemoveInArray(this.updatedItems, causeId);
    },
    doneSelected() {
      this.$emit('done', this.updatedItems);
      $('#' + this.id).modal('hide');
      this.activeIndex = 0;
    }
  },
  computed : {
    ...mapGetters([
      'company_root_causes_list'
    ]),
    rootCauses() {
      return this.company_root_causes_list[this.$route.params.type][this.activeIndex];
    },
    chunkCausesChildren() {
      return _.chunk(this.rootCauses?.children, 10);
    },
    allChildCauses() {
      return [].concat(...this.company_root_causes_list[this.$route.params.type].map(cause => cause.children));
    },
    updatedItemsValue() {
      return this.allChildCauses.filter(cause => {
        return this.updatedItems.includes(cause.id);
      });
    }

  },
  async created() {
    await this.getCompanyRootCausesList(this.$route.params.type);
    this.items.forEach(item => {
      this.updatedItems.push(item);
    })
  },
}
</script>

<style scoped>
input[type='checkbox']{
  width: 20px;
  height: 20px;
}
.modal-dialog{
  overflow-y: initial !important
}
.modal-body{
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}


.input-group .btn-link {
  padding: 0;
  border: none;
  font-size: 1.25rem;
  color: #000;
  text-decoration: none;
  position: absolute;
  right: 5px;
  z-index: 1000;
}

.input-group .btn-link:hover {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}

.input-group .btn-link:focus {
  box-shadow: none;
}

.modal-content {
  box-shadow : rgba(0, 0, 0, 0.5) 0px 0px 50px 50px;
}

.active_custom {
  background-color: #d3313c;
  color: #fff;
}

.close-selected-cause {
  color: red;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: -7px;
}

.word-wrap{
  display: inline-flex;
}

</style>
