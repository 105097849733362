import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      oe : this.setOeDetailInitialValues(),
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
    }
  },
  methods: {
    ...mapActions([
      'getCompanyNonConformities',
      'getCompanyOECategoriesList',
      'updateCompanyOEImageAttachment'
    ]),
    setOeDetailInitialValues() {
      return {
        id : null,
        category_id : null,
        description : null,
        ca_request : '',
        reference : '',
        rectified : 0,
        rectified_date : null,
        rectified_comment : ''
      }
    },
    setOneOrZero(value) {
      return value === 0 ? 1 : 0;
    },
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  computed : {
    ...mapGetters([
      'companyOECategories'
    ])
  },
  async created() {
    await this.getCompanyOECategoriesList();
  }
}
